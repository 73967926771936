<!-- =========== MAIN PAGE CONTAINER ============== -->
<div
	class="main-container"
	style="
		display: flex;
		flex-direction: column;
		height: 100%;
		background-image: url('../../../../assets/images/Trb-latest-svg/Background-img.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	"
>
	<div
		*ngIf="headerWithImage"
		class="header-container"
		style="
			width: inherit;
			height: 20%;
			background-image: url('../../../../assets/newlayout/navbar-image/maldives-image-min.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
		"
	>
		<app-top-navbar [sideBarExpanded]="sideBarExpanded" (onTogglerClick)="toggleSideNav()"></app-top-navbar>
	</div>

	<div *ngIf="!headerWithImage" class="header-container">
		<app-top-navbar [sideBarExpanded]="sideBarExpanded" (onTogglerClick)="toggleSideNav()"></app-top-navbar>
	</div>

	<div *ngIf="showBreadcrumb" style="height: 50px; margin: 20px 30px 20px 30px">
		<app-breadcrumb (showHideSpinner)="showHideSpinner($event)"></app-breadcrumb>
	</div>

	<div class="body-container">
		<ngx-spinner [name]="contentContainerSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
			<p style="color: white">Loading...</p>
		</ngx-spinner>
		<ng-container #dynamicComponent></ng-container>
	</div>

	<!-- ============ FOOTER COMPONENT STYLE ================== -->
	<app-footer></app-footer>
</div>
