import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Logger } from '../../../../goldstar-share/src/app/services/logging/logger';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(private logger: Logger) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const startTime = Date.now();
		const requestUrl = req.urlWithParams;

		if (req.url.includes('log/put')) return next.handle(req);
		return next.handle(req).pipe(
			tap(
				(event) => {
					const endTime = Date.now();
					const elapsedTime = endTime - startTime;
					const logMessage = `HTTP Request completed: ${req.method} ${requestUrl} (${elapsedTime}ms)`;
					this.logger.debug('HttpRequestInterceptor', logMessage, {});
				},
				(error) => {
					this.logger.critical('HttpRequestInterceptor', error);
				}
			)
		);
	}
}
