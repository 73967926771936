import { Injectable } from '@angular/core';
import { MenuService } from '../../services/menuService';
import { MenuGroupUIModel, MenuUIModel } from '../models/models';
import { MenuGroupModel, MenuItemModel } from '../../models/models';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/classes';
import { FavoriteMenuUpdateAction, MenuLoadedStateAction } from '../../store/store.actions';
import { cloneDeep } from 'lodash';
import { CollectionHelper } from '../../../../../goldstar-share/src/app/services/collection.helper';
import { SingleMenuGroup } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';

@Injectable({
	providedIn: 'root',
})
export class DashboardMenuService {
	constructor(private menuService: MenuService, private store: Store<AppState>) {}

	/**
	 * Search Menu groups and its items
	 * @param searchTerm
	 */
	public async searchMenu(searchTerm: string): Promise<MenuGroupUIModel[]> {
		const allMenuItems = await this.menuService.searchMenuItem(searchTerm);

		let menuGroupUIModel = allMenuItems.map((menuGroup: MenuGroupModel) => {
			let menuGroupUIModel: MenuGroupUIModel = {
				name: menuGroup.name ?? 'Demo',
				iconName: menuGroup.imageURL ?? '',
				items: menuGroup.menuItems
					.filter((x) => x.isActive)
					.map((menuItem: MenuItemModel) => {
						let menuUIItem: MenuUIModel = {
							urlGUID: menuItem.menuGUID,
							name: menuItem.name ?? 'Demo Menu',
							selected: false,
							menuItem: menuItem,
							items: this.processChildMenu(
								menuGroup.menuItems.filter((x) => x.parentMenuGUID === menuItem.menuGUID && x.isActive),
								menuGroup
							),
							nestedChild: false,
							childVisible: false,
							isExternalLink: menuItem.isExternalLink ?? false,
							externalLinkURL: menuItem.externalLinkURL,
						};
						return menuUIItem;
					}),
				selected: false,
			};
			return menuGroupUIModel;
		});

		return menuGroupUIModel;
	}

	/**
	 * Get the top level dashboard model
	 * @returns
	 */
	public async prepareDashboardMenu(): Promise<MenuGroupUIModel[]> {
		const allMenuItems = await this.menuService.loadAllMenus();
		const filteredMenuGroups: MenuGroupUIModel[] = [];

		allMenuItems.forEach((menuGroup: MenuGroupModel) => {
			let menuGroupUIModel: MenuGroupUIModel = {
				name: menuGroup.name ?? 'Demo',
				iconName: menuGroup.imageURL ?? '',
				items: menuGroup.menuItems
					.filter((x) => (x.parentMenuGUID == null || x.parentMenuGUID == undefined) && x.isActive)
					.map((menuItem: MenuItemModel) => {
						let menuUIItem: MenuUIModel = {
							urlGUID: menuItem.menuGUID,
							name: menuItem.name ?? 'Demo Menu',
							selected: false,
							menuItem: menuItem,
							items: this.processChildMenu(
								menuGroup.menuItems.filter((x) => x.parentMenuGUID === menuItem.menuGUID && x.isActive),
								menuGroup
							),
							nestedChild: false,
							childVisible: false,
							isExternalLink: menuItem.isExternalLink ?? false,
							externalLinkURL: menuItem.externalLinkURL,
						};
						return menuUIItem;
					}),
				selected: false,
			};
			if (menuGroupUIModel.items.length > 0) {
				filteredMenuGroups.push(menuGroupUIModel);
			}
		});

		return filteredMenuGroups;
	}

	public async updateSortOrder(menuGroupItems: SingleMenuGroup[]){
		this.menuService.updateSortOrderForMenus(menuGroupItems);
	}

	private processChildMenu(children: MenuItemModel[], menuGroup: MenuGroupModel): MenuUIModel[] {
		let childMenuItems = children.map((menuItem: MenuItemModel) => {
			let childMenu: MenuUIModel = {
				urlGUID: menuItem.menuGUID,
				name: menuItem.name ?? 'Child Menu',
				nestedChild: true,
				selected: false,
				menuItem: menuItem,
				childVisible: false,
				isExternalLink: menuItem.isExternalLink ?? false,
				externalLinkURL: menuItem.externalLinkURL,
				items: this.processChildMenu(
					menuGroup.menuItems.filter((x) => x.parentMenuGUID === menuItem.menuGUID),
					menuGroup
				),
			};
			return childMenu;
		});
		return childMenuItems;
	}

	/**
	 * Mark menu as favorite
	 */
	public async markMenuAsFavorite(favoriteMenu: MenuItemModel): Promise<void> {
		if (favoriteMenu.isFavorite) {
			this.menuService.addUserFavoriteMenu(favoriteMenu, [
				() => {
					const clonedFavoriteMenus = cloneDeep(this.menuService.favoriteMenuCollection);
					this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
					this.menuService.markBreadcrumbFavorite(favoriteMenu.menuGUID ?? '');
				},
			]);
		} else {
			this.menuService.menuGroupCollection.forEach((x) => {
				const matchingItem = x.menuItems.find((y) => y.menuGUID === favoriteMenu.menuGUID);
				if (matchingItem) {
					matchingItem.isFavorite = favoriteMenu.isFavorite;
				}
			});

			// Refresh the favorite menus
			const clonedFavoriteMenus = cloneDeep(this.menuService.favoriteMenuCollection);
			this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));

			const allMenuList = CollectionHelper.selectMany(this.menuService.menuGroupCollection, (x) => x.menuItems);
			this.menuService.updateFavoriteMenuRemote(allMenuList);
			this.menuService.markBreadcrumbFavorite(favoriteMenu.menuGUID ?? '');
		}
	}
}
