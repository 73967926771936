import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { OutdatedBuildAction } from '../store/store.actions';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
	constructor(private store: Store<AppState>,) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // DO NOTHING IF THE RESPONSE IS A VALID HTTP RESPONSE
            }
            }),
			catchError((error: HttpErrorResponse) => {
                if(error.status == HttpStatusCode.MethodNotAllowed){
                    console.error('Invalid Build Error', error);
                    this.store?.dispatch(OutdatedBuildAction({ apiBuildMinorVersion: {
                        couter: 1,
                        buildNumber:-1
                    } }));
                }
                return throwError(()=> new Error('Invalid Build Error'));
            })
		);
	}
}
