import { createReducer, on } from '@ngrx/store';
import { StoreState } from '../models/classes';
import {
	ApplyMarginOnBodyAction,
	ChatMessageAddedAction,
	ChatMessagesUpdatedAction,
	FavoriteMenuUpdateAction,
	LoginStateAction,
	MenuGroupUpdateAction,
	MenuLoadedStateAction,
	NavbarTitleUpdatedAction,
	NotificationAddedAction,
	NotificationsUpdatedAction,
	RecentMenuUpdateAction,
	ScreenSizeStateAction,
	BreadcrumbUpdateAction,
	WikiModuleStateAction,
	BreadcrumbFavoriteIconToggleAction,
	UserPermissionLoadedAction,
	BreadcrumbMarkFavoriteAction,
	BreadcrumbIconsToggleAction,
	DirtySaveHasUnsavedChangeAction,
	SuggestionUpdateAction,
	MenuGroupSortOrderUpdatedAction,
	OutdatedBuildAction,
} from './store.actions';

export const initialStoreState: StoreState = {
	selectedContact: undefined,
	isLoggedIn: false,
	userId: '',
	menuLoaded: false,
	screenSizeInfo: undefined,
	notificationsModifiedList: [],
	notificationAdded: undefined,
	chatMessagesModifiedList: [],
	chatMessageAdded: undefined,
	navbarTitleUpdated: '',
	applyMarginOnBody: true,
	menuGroupUpdated: [],
	recentMenuGroupUpdated: [],
	favoriteMenuGroupUpdated: [],
	breadcrumb: [],
	isWikiModuleDataUpdated: false,
	showFavoriteIconOnBreadcrumb: true,
	userPermissionList: undefined,
	breadCrumbFavoriteMenu: [],
	breadcrumbActions: undefined,
	hasUnSavedChanges: false,
	suggestionUpdated: false,
	userProfileUpdated: false,
	menuGroupSortOrder: [],
	apiBuildMinorVersion: undefined,
};

export const storeReducer = createReducer(
	initialStoreState,

	//on(selectedContactAction, (selectedPipelineContact) => selectedPipelineContact),
	on(LoginStateAction, (state, payload) => {
		return {
			...state,
			isLoggedIn: payload.isLoggedIn,
			userId: payload.userId,
		};
	}),

	// menu loaded action
	on(MenuLoadedStateAction, (state, payload) => {
		return {
			...state,
			menuLoaded: payload.menuLoaded,
		};
	}),

	// screen size updated action
	on(ScreenSizeStateAction, (state, payload) => {
		return {
			...state,
			screenSizeInfo: payload.screenSizeInfo,
		};
	}),

	// Notification (both of type Topic and Notification) collection modified
	on(NotificationsUpdatedAction, (state, payload) => {
		return {
			...state,
			notificationsModifiedList: payload.notifications,
		};
	}),

	// Notification (both of type Topic and Notification) Added
	on(NotificationAddedAction, (state, payload) => {
		return {
			...state,
			notificationAdded: payload.notification,
		};
	}),

	// ChatMessages collection modified
	on(ChatMessagesUpdatedAction, (state, payload) => {
		return {
			...state,
			chatMessagesModifiedList: payload.chatMessages,
		};
	}),

	// ChatMessage Added
	on(ChatMessageAddedAction, (state, payload) => {
		return {
			...state,
			chatMessageAdded: payload.chatMessage,
		};
	}),

	// Navigation Title updated
	on(NavbarTitleUpdatedAction, (state, payload) => {
		return {
			...state,
			navbarTitleUpdated: payload.title,
		};
	}),

	// Apply Margin on body layout
	on(ApplyMarginOnBodyAction, (state, payload) => {
		return {
			...state,
			applyMarginOnBody: payload.applyMargin,
		};
	}),

	// MenuGroup updated action
	on(MenuGroupUpdateAction, (state, payload) => {
		return {
			...state,
			menuGroupUpdated: payload.menuGroups,
		};
	}),

	on(RecentMenuUpdateAction, (state, payload) => {
		return {
			...state,
			recentMenuGroupUpdated: payload.menuList,
		};
	}),

	on(FavoriteMenuUpdateAction, (state, payload) => {
		return {
			...state,
			favoriteMenuGroupUpdated: payload.menuList,
		};
	}),

	on(BreadcrumbUpdateAction, (state, payload) => {
		return {
			...state,
			breadcrumb: payload.breadcrumb,
		};
	}),

	on(WikiModuleStateAction, (state, payload) => {
		return {
			...state,
			isWikiModuleDataUpdated: payload.isWikiModuleDataUpdated,
		};
	}),

	on(MenuGroupSortOrderUpdatedAction, (state, payload) => {
		return {
			...state,
			menuGroupSortOrder: payload.menuGroupSortOrderUpdated,
		};
	}),
	/* <summary>
	date: 21-11-2023
	Name: Gourish Mesta
	description: Toggle the favorite icon on breadcrumb
	<summary>*/
	on(BreadcrumbFavoriteIconToggleAction, (state, payload) => {
		return {
			...state,
			showFavoriteIconOnBreadcrumb: payload.showFavoriteIconOnBreadcrumb,
		};
	}),

	/**
	 * All User Permission initialized actions
	 */
	on(UserPermissionLoadedAction, (state, payload) => {
		return {
			...state,
			userPermissionList: payload.permissionList,
		};
	}),
	on(BreadcrumbIconsToggleAction, (state, payload) => {
		return {
			...state,
			breadcrumbActions: {
				...state.breadcrumbActions,
				showEditIconOnBreadcrumb: payload.showEditIconOnBreadcrumb ?? false,
				showEyeIconOnBreadcrumb: payload.showEyeIconOnBreadcrumb ?? false,
			},
		};
	}),
	on(BreadcrumbMarkFavoriteAction, (state, payload) => {
		return {
			...state,
			breadCrumbFavoriteMenu: payload.breadcrumb,
		};
	}),
	on(DirtySaveHasUnsavedChangeAction, (state, payload) => {
		return {
			...state,
			hasUnSavedChanges: payload.hasUnSavedChanges ?? false,
		};
	}),
	on(SuggestionUpdateAction, (state, payload) => {
		return {
			...state,
			suggestionUpdated: payload.suggestionUpdated ?? false,
		};
	}),
	on(OutdatedBuildAction, (state, payload) => {
		return {
			...state,
			apiBuildMinorVersion: payload.apiBuildMinorVersion,
		};
	}),
);
